import React, { useState } from 'react';
import {CSSTransition, SwitchTransition} from 'react-transition-group';

import useDidMountEffect from '../custom-hooks/useDidMountEffect';
import StatesList from './StatesList';
import AffiliatesList from './AffiliatesList';
import USMap from './USMap';
import AdBlock from './AdBlock';

import './../scss/FindUs.scss';

function FindUs({
    affiliates,
    network,
    hideSelectOnResults,
    autoScrollOffset
}) {
    const [stateId, setStateId] = useState('');
    const [viewResults, setViewResults] = useState(false);

    const resultsListSelector = '.find-us__results'

    /**
     * A custom hook that's not fired on the
     * initial load
     */
    useDidMountEffect(() => {
       toggleResultsView();
    }, [stateId]);  
    
    // event handlers ====

    /**
     * Updates the stateId when a new one is selected
     * 
     * @param {the state id value} abbr 
     * @param {the event responsible for the update} e 
     */
    function stateSelected(abbr, e) {
        setStateId(abbr);
    };

    /**
     * Handles toggling the results list
     */
    function toggleResultsView() {
        if (hideSelectOnResults) {
            setViewResults(!viewResults);
        } else {
            if (!viewResults) setViewResults(true);
            scrollToResults();
        }
    }
    
    // helper functions ====

    /**
     * Scrolls the results list into view 
     */
    function scrollToResults() {  
        setTimeout(() => {
            let el;
            if (el = document.querySelector(resultsListSelector)) {              
                window.scroll({
                    left: 0, 
                    top: (el.getBoundingClientRect().y + window.pageYOffset) -  autoScrollOffset,
                    behavior: 'smooth',
                });
            }
        }, 100);   
    }

    // render ====

    if (hideSelectOnResults) {
        return (
            <section className="find-us"> 
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={viewResults}
                        timeout={350}
                        onExited={scrollToResults}
                        classNames="fade"
                    >                    
                        {viewResults ? 
                            <div className="find-us__results">
                                <AffiliatesList 
                                    toggleResultsView={toggleResultsView} 
                                    stateId={stateId} 
                                    setStateId={setStateId}
                                    affiliates={affiliates} 
                                    network={network} 
                                    hideSelectOnResults={hideSelectOnResults} 
                                />
                            </div>
                            :
                            <div className="find-us__selector constrain-content">
                                <USMap 
                                    stateSelectedHandler={stateSelected} 
                                    stateId={stateId} 
                                    hideSelectOnResults={hideSelectOnResults}
                                />
                                <StatesList 
                                    stateSelectedHandler={stateSelected} 
                                    stateId={stateId}
                                />
                            </div>                     
                        }                
                    </CSSTransition>
                </SwitchTransition>
            </section>
        );
    } else {
        return (
            <section className="find-us">
                <div className="find-us__selector constrain-content">
                    <USMap 
                        stateSelectedHandler={stateSelected} 
                        stateId={stateId} 
                        hideSelectOnResults={hideSelectOnResults}
                    />
                    <StatesList 
                        stateSelectedHandler={stateSelected} 
                        stateId={stateId} 
                    />
                </div>
                <div className="find-us__ad-wrapper">
                    <AdBlock 
                        ad={{
                            header: 'Fall in love with subscription-free TV.',
                            description: `Watch popular shows for free. It's not fantasy, it's reality.`,
                            href: `https://www.tablotv.com`,
                            linkText: 'See Deals',
                            campaign: 'leadgen',
                            source: 'ionmystery.com',
                            medium: 'referral',
                            content: 'findus_jessefantasy',
                        }}
                        image={{
                            url:'https://cdn.scrippscloud.com/web/images/tablo/logos/Tablo_TV_Logo_2Color_White_RGB-cropped.png',
                            mobile: 'https://cdn.scrippscloud.com/web/images/tablo/logos/Tablo_TV_Logo_2Color_Navy_RGB-cropped.png',
                            altText: 'TABLO TV',
                            text: 'Internet serv. and antenna purchase req. User interface subject to change. Channels & content subj. to avail.',
                        }}
                        theme='tablotv'
                        
                    />
                </div>
                <CSSTransition
                    in={viewResults}
                    timeout={1000}
                    classNames="fade-slow"
                >       
                    <div className="find-us__results">
                        { (viewResults) &&                         
                            <AffiliatesList 
                                toggleResultsView={toggleResultsView} 
                                stateId={stateId} 
                                setStateId={setStateId}
                                affiliates={affiliates} 
                                network={network} 
                                hideSelectOnResults={hideSelectOnResults} 
                            />
                        }
                    </div>
                </CSSTransition>
            </section>
        );
    }
}

export default FindUs;
