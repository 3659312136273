const convertStartTime = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');

    if (hours === '12') hours = '0';
    hours = parseInt(hours, 10)
    if (modifier === 'PM') hours += 12;

    return [hours, minutes];
}

export {
    convertStartTime,
}