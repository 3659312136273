import React, { useRef, useEffect ,} from 'react';

import { RadioSVGMap } from "react-svg-map";
import USA from "@svg-maps/usa";
import './../scss/USMap.scss';

function USMap({
    stateId,
    stateSelectedHandler
}) {
    const currentSelected = useRef('');
    
    useEffect(() => {
        /** 
         * if state is changed by outside source select
         * the new state 
        */
        if (currentSelected.current === '' && stateId !== '') {
            currentSelected.current = stateId;
            document.getElementById(stateId).setAttribute('aria-checked', true);
        } else if (currentSelected.current !== stateId && stateId !== '') {
            document.getElementById(currentSelected.current).setAttribute('aria-checked', false);
            document.getElementById(stateId).setAttribute('aria-checked', true);
            currentSelected.current = stateId;
        }
    },[stateId]);

    // event handlers
    /**
     * Updates stateId in parent component
     * @param {element node passed from RadioSVGMap} node 
     */
    function stateSelectedOnMap(node) {
        stateSelectedHandler(node.id, {});
    }

    return (
        <section className="usa-map">
            <RadioSVGMap map={USA} onChange={stateSelectedOnMap}/>
        </section>
    );
}

export default USMap;