import React, { useContext } from 'react'
import format from 'date-fns/format'
import { ScheduleContext } from '../contexts/ScheduleContext'
import { useQuery } from 'react-query'
import { convertStartTime } from '../utils/ConvertStartTime'
import Working from './Working'

import './../scss/ScheduleList.scss'

const getSchedule = async ({ queryKey }) => {
  const [_key, { day }] = queryKey
  
  // const getAccessToken = async () => {
  //     let response = await fetch(process.env.REACT_APP_OA, {
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/x-www-form-urlencoded',
  //             'Authorization': `Basic ${process.env.REACT_APP_CT}`,
  //         }
  //     })

  //    return await response.json()
  // }

  const getSchedule = async (at) => {
    let endpoint = `${process.env.REACT_APP_SCHEDULE}?start_date__range=${day},${day}&offset=0&limit=100`

    let response = await fetch(endpoint)

    return response.json()
  }

  // const getNewAt = async () => {
  //   let atResult = await getAccessToken()
  //   at = atResult?.access_token ?? ''
  //   if (at !== '') sessionStorage.setItem('snn:at', JSON.stringify({at, setAt: new Date().getTime()})); 

  //   return at;
  // } 
  

  // let at = '';
  // if (sessionStorage.getItem('snn:at')) {
  //   const snnAt = JSON.parse(sessionStorage.getItem('snn:at'));
  //   if ((snnAt.setAt + 86400000) > new Date().getTime()) {
  //     at = snnAt.at
  //   } else {
  //     at = await getNewAt() 
  //   }
  // } else {
  //   at = await getNewAt()
  // }

  const schedule = await getSchedule()

  const nightSchedule = schedule.results.filter((slot) => {
    return slot.end_time.includes('XM') && !slot.start_time.includes('PM')
  });

  const daySchedule = schedule.results.filter((slot) => {
    return !slot.end_time.includes('XM') || (slot.end_time.includes('XM') && slot.start_time.includes('PM'))   
  }); 

  return [...daySchedule, ...nightSchedule]
}

const getStartTime = (startTime) => {
  let [hours, mins] = convertStartTime(startTime)

  return format(new Date(0, 0, 0, hours, mins, 0), 'h:mm aa')
}

const getRunTime = (runTime) => {
  let [hours, mins] = runTime.split(':')
  let runTimeStr = ''

  if (hours !== '00') runTimeStr += `${parseInt(hours, 10)} ${(parseInt(hours, 10) > 1) ? 'hours' : 'hour'}`;
  if (hours !== '00' && mins !== '00') runTimeStr += ' and '
  if (mins !== '00') runTimeStr += `${parseInt(mins)} ${(parseInt(mins, 10) > 1) ? 'minutes' : 'minute'}`
  
  return runTimeStr
}

function ScheduleList() {
  const {day} = useContext(ScheduleContext)
  const { isSuccess, isLoading, isError, data: schedule } = useQuery(
    [`schedule:${format(new Date(day), 'yyyy-MM-dd')}`, { resourceName: 'schedule', resourceId: format(new Date(day), 'yyyy-MM-dd'),  day: format(new Date(day), 'yyyy-MM-dd') }], getSchedule)

  return (
    <div className='constrain-content'>
      <div className='schedule-list__timezone'>All Times Listed in Eastern Timezone</div>
      <ul className='schedule-list'>
        {isLoading && <li style={{height:'100vh'}}><Working /></li>}
        {isSuccess && schedule.map(program => {
          return (
            <li className='schedule-list__item' key={`${program.start_date}{${program.start_time}}`}>
                <div className='schedule-item__time'>{getStartTime(program.start_time)}</div>
                <div className='schedule-item__program'>{program.program}</div>
                <div className='schedule-item__title'>{program.title_name}</div>
                <div className='schedule-item__synopsis'>{program.title_synopsis}</div>
                <div className='schedule-item__cast'>{program.cast ?? ''}</div>
                <div className='schedule-item__rating'>{program.rating} ({getRunTime(program.element_length)})</div>
            </li>
          )
        })}
        {isError && <li>Sorry we can't display the schedule at this time. Please check back shortly.</li>}
      </ul>
    </div>
  )
}

export default ScheduleList