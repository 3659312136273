import React, { useContext, useEffect, useState } from 'react'
import { formatISO9075, format, add, eachDayOfInterval } from 'date-fns'
import { ScheduleContext } from '../contexts/ScheduleContext'

import './../scss/ScheduleNav.scss'

function ScheduleNav() {
    const scheduleStartDate = new Date();
    const scheduleEndDate = add(new Date(), { days: 7 });
    const days = eachDayOfInterval({
        start: scheduleStartDate,
        end: scheduleEndDate,
    });

    const [dayCompare, setDayCompare] = useState(formatISO9075(new Date(), {representation: 'date'}))

    const {day, setDay} = useContext(ScheduleContext)   
    
    useEffect(() => {
        setDayCompare(formatISO9075(day, {representation: 'date'}))
    }, [day])
    

    return (
        <nav className='schedule-nav'>
            <div className='constrain-content'>
                <ul className='schedule-nav__list'>
                    {days.map((d) => {
                        let dCompare = formatISO9075(d, {representation: 'date'})
                        return (
                            <li className='schedule-nav__day' key={`day-${format(d,'MMdd')}`}>
                                <button onClick={() => setDay(d)} className={(dCompare === dayCompare) ? `active` : ``}>
                                    <div className='day__abbr'>{format(d, 'iii')}</div>
                                    <div className='day__date'>{format(d, 'MM/dd')}</div>
                                </button>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </nav>
    )
}

export default ScheduleNav