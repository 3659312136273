import React from 'react'

import './../scss/PageHero.scss'

function PageHero() {
  return (
    <div className="page-hero">
      <div className='page-hero__content constrain-content'>
        <h1 className='main-logo'>
          <span className='sr-only'>ION Mystery</span>
        </h1>
        <div className='page-hero__description'>
          ION Mystery invites you to join the investigation every day of the week, with edge-of-your-seat thrillers that will keep you guessing until the very end.
        </div>
      </div>
    </div>
  )
}

export default PageHero