import React, { useEffect, useRef, useCallback } from 'react'

import tabloTvTheme from '../scss/ad-themes/TabloTv.module.scss'
import imageOnlyTheme from '../scss/ad-themes//ImageOnly.module.scss'

function AdBlock({ image, ad, theme = 'imageOnly'}) {
    const themeMap = new Map([
        ['tablotv', tabloTvTheme],
        ['imageonly', imageOnlyTheme]
    ])
    const styles = themeMap.get(theme)
    const linkParams = (ad.campaign === '') ? '' : `?utm_campaign=${ad.campaign}&utm_source=${ad.source}&utm_medium=${ad.medium}&utm_content=${ad.content}`
    const adCopyRef = useRef()
    const adImageRef = useRef()
    const ctaRef = useRef()
    const logoRef = useRef()
    const mobileLogoRef = useRef()
    const mobileDisclaimerRef = useRef()

    const adLink = `${ad.href}${linkParams}`;

    const triggerAnimation = useCallback((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {      
                if (theme === 'tablotv') {
                    ctaRef.current.classList.add(`${styles['ad-cta--animate']}`)
                    mobileDisclaimerRef.current.classList.add(`${styles['ad-mobile-disclaimer--animate']}`)
                }
                adCopyRef.current.classList.add(`${styles['ad-copy--animate']}`)
                adImageRef.current.classList.add(`${styles['ad-image--animate']}`)
                
                observer.unobserve(adImageRef.current)
            }
        })
    }, [styles])

    useEffect(() => {
        if (theme !== 'imageonly') {
          const adimageObserver = new IntersectionObserver(triggerAnimation, {
            root: null,
            rootMargin: "0px",
            threshold: 0.1
          })
          adimageObserver.observe(adImageRef.current)
    
          return () => {
            adimageObserver.disconnect()
          }
        }
      }, [theme, triggerAnimation])

    return (
        <ad-block
            class={`${styles['ad-anchor']}`}
            onClick={(event) => {
                // ensure tab isn't opened twice if clicking directly on the cta
                if (
                    event.target === ctaRef.current || 
                    event.target === logoRef.current || 
                    event.target === mobileLogoRef.current
                ) {
                  event.preventDefault();
                }
                window.open(ctaRef.current, '_blank');
              }}
        >

        {theme === 'imageonly' ? (
            <a className={styles['cta-container__link']} href={adLink} target="_blank" rel="noreferrer" ref={ctaRef}>
                <span className="sr-only">{image.text}</span>
            </a>
        ) : (
            <div className={styles['background-container']}>
                <div className={styles['ad-copy']} ref={adCopyRef}>
                    <h4 className={styles['ad-copy__header']}>{ad.header}</h4>
                    <div className={styles['ad-image-mobile']} ref={adImageRef}>
                        <ad-image>
                            <a href={adLink} target="_blank" rel="noreferrer">
                                <img src={image.mobile} alt={image.altText} ref={mobileLogoRef}/>
                            </a>
                        </ad-image>
                    </div>
                    <p className={styles['ad-copy__description']}>{ad.description}</p>
                </div>
                <div className={styles['cta-container']}>
                    <a className={styles['cta-container__link']} href={adLink} target="_blank" rel="noreferrer" ref={ctaRef}>{ad.linkText}</a>
                    <span className={styles['ad-image__text']} ref={mobileDisclaimerRef}>{ image.text }</span>
                </div>
                <div className={styles['ad-image']} ref={adImageRef}>
                    <ad-image>
                        <a href={adLink} target="_blank" rel="noreferrer">
                            <img src={image.url} alt={image.altText} ref={logoRef}/>
                        </a>
                    </ad-image>
                    <span className={styles['ad-image__text']}>{ image.text }</span>
                </div>
            </div>
        )}
        </ad-block>
    )
}

export default AdBlock