const statesList = [
    { name: 'Alabama', abbreviation: 'al'},
    { name: 'Alaska', abbreviation: 'ak'},
    { name: 'Arizona', abbreviation: 'az'},
    { name: 'Arkansas', abbreviation: 'ar'},
    { name: 'California', abbreviation: 'ca'},
    { name: 'Colorado', abbreviation: 'co'},
    { name: 'Connecticut', abbreviation: 'ct'},
    { name: 'Delaware', abbreviation: 'de'},
    { name: 'District Of Columbia', abbreviation: 'dc'},
    { name: 'Florida', abbreviation: 'fl'},
    { name: 'Georgia', abbreviation: 'ga'},
    { name: 'Hawaii', abbreviation: 'hi'},
    { name: 'Idaho', abbreviation: 'id'},
    { name: 'Illinois', abbreviation: 'il'},
    { name: 'Indiana', abbreviation: 'in'},
    { name: 'Iowa', abbreviation: 'ia'},
    { name: 'Kansas', abbreviation: 'ks'},
    { name: 'Kentucky', abbreviation: 'ky'},
    { name: 'Louisiana', abbreviation: 'la'},
    { name: 'Maine', abbreviation: 'me'},
    { name: 'Maryland', abbreviation: 'md'},
    { name: 'Massachusetts', abbreviation: 'ma'},
    { name: 'Michigan', abbreviation: 'mi'},
    { name: 'Minnesota', abbreviation: 'mn'},
    { name: 'Mississippi', abbreviation: 'ms'},
    { name: 'Missouri', abbreviation: 'mo'},
    { name: 'Montana', abbreviation: 'mt'},
    { name: 'Nebraska', abbreviation: 'ne'},
    { name: 'Nevada', abbreviation: 'nv'},
    { name: 'New Hampshire', abbreviation: 'nh'},
    { name: 'New Jersey', abbreviation: 'nj'},
    { name: 'New Mexico', abbreviation: 'nm'},
    { name: 'New York', abbreviation: 'ny'},
    { name: 'North Carolina', abbreviation: 'nc'},
    { name: 'North Dakota', abbreviation: 'nd'},
    { name: 'Ohio', abbreviation: 'oh'},
    { name: 'Oklahoma', abbreviation: 'ok'},
    { name: 'Oregon', abbreviation: 'or'},
    { name: 'Pennsylvania', abbreviation: 'pa'},
    { name: 'Rhode Island', abbreviation: 'ri'},
    { name: 'South Carolina', abbreviation: 'sc'},
    { name: 'South Dakota', abbreviation: 'sd'},
    { name: 'Tennessee', abbreviation: 'tn'},
    { name: 'Texas', abbreviation: 'tx'},
    { name: 'Utah', abbreviation: 'ut'},
    { name: 'Vermont', abbreviation: 'vt'},
    { name: 'Virginia', abbreviation: 'va'},
    { name: 'Washington', abbreviation: 'wa'},
    { name: 'West Virginia', abbreviation: 'wv'},
    { name: 'Wisconsin', abbreviation: 'wi'},
    { name: 'Wyoming', abbreviation: 'wy' }
];

const scheduleEndpoint = 'https://api-internal.scrippscloud.com/schedules/ionmystery'
const affiliateEndpoint = 'https://api.scrippscloud.com/affiliates/ion-mystery' //'https://utilitystaging.bouncetv.com/affiliates/court/'

export {
    statesList,
    scheduleEndpoint,
    affiliateEndpoint,
}