function StateListButton({
    state,
    stateId,
    stateSelectedHandler
}) {
    let buttonClasses = ["states-list__link"];
    if (state.abbreviation === stateId) buttonClasses.push('states-list__selected');

    return (
        <button 
            onClick={(e) => stateSelectedHandler(state.abbreviation, e)} 
            className={buttonClasses.join(' ')}
        >{ state.name }</button>
    );
}

export default StateListButton;