import React, { useContext } from 'react'
import { MobileMenuContext } from './../contexts/MobileMenuContext'
import { FaFacebookF, FaTwitter, FaTiktok } from 'react-icons/fa'

import './../scss/SmallScreenNav.scss'

function MobileNavigation() {
    const { setIsMobileMenuActive, isMobileMenuActive } = useContext(MobileMenuContext)
 
  return (
    <nav className={isMobileMenuActive ? `small-screen-nav small-screen-nav--active` : `small-screen-nav`} role="navigation">
        <button onClick={() => setIsMobileMenuActive(false)} type="button" className="small-screen-nav__close">&times;</button>
        <ul className='small-screen-nav__main'>
            <li>
                <a href={`${window.location.protocol}//${window.location.host}/`} className="small-screen-nav__ion-mystery">
                    <span className="sr-only">ION Mystery</span>
                </a>
            </li>
            <li>
                <a href="/#find-us" onClick={() => setIsMobileMenuActive(false)} className='find-us-link find-us-link--mobile'>
                    Find ION Mystery
                </a>
            </li>
        </ul>     
        <div className='small-screen-nav__social'>
            <a href="https://www.facebook.com/ionmystery/" target="_blank" rel="noreferrer">
                <span className="sr-only">Facebook</span>
                <FaFacebookF/>
            </a>
            <a href="https://mobile.twitter.com/ionmystery" target="_blank" rel="noreferrer">
                <span className="sr-only">Twitter</span>
                <FaTwitter />
            </a>
            <a href="https://www.tiktok.com/@ionmystery?lang=en" target="_blank" rel="noreferrer">
                <span className="sr-only">TikTok</span>
                <FaTiktok />
            </a>
        </div>
        <hr className='small-screen-nav__seperator'/>
        <div className='small-screen-nav__related-sites'>
            <a className="related-sites__link related-sites--ion" href="https://iontelevision.com" target="_blank" rel="noreferrer">
                <span className='sr-only'>ION Televison</span>
            </a>
            <a className="related-sites__link related-sites--ion-plus" href="https://ionplustv.com" target="_blank" rel="noreferrer">
                <span className='sr-only'>ION Plus</span>
            </a>
        </div>
    </nav>
  )
}

export default MobileNavigation