import StateListButton from './../components/StateListButton';

import { statesList } from './../settings/AppSettings';
import './../scss/StatesList.scss';

function StatesList({
    stateId,
    stateSelectedHandler
}) {
    return (
        <section className="states-list">
            <h3 className="states-list__header">Select Your State</h3>
            <ul className="states-list__list">
                {statesList.map((state) => (
                    <li className="states-list__item" key={state.abbreviation}>
                        <StateListButton state={state} stateId={stateId} stateSelectedHandler={stateSelectedHandler}/>
                    </li>)       
                )}            
            </ul>
            <select value={stateId} onChange={(e) => { if (e.target.value !== '') stateSelectedHandler(e.target.value, e)}} className="states-list__mobile-list">
                <option value={''}>Select Your State</option>
                {statesList.map((state) => (
                    <option value={state.abbreviation} key={`opt-${state.abbreviation}`}>{state.name}</option>
                ))}
            </select>  
        </section>
    );
};

export default StatesList;