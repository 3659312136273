import React from 'react'
import { FaSearch } from 'react-icons/fa'


import './../../scss/Header.scss'
import MainNavigation from './MainNavigation'

function Header() {
    return (        
        <MainNavigation/>
    )
}

export default Header