import React from 'react'
import BaseLayout from './layouts/BaseLayout'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from './pages/Home'

import './scss/Settings.scss'
import './scss/Base.scss'

function App() {
    return (
        <BrowserRouter>            
            <BaseLayout> 
                <Routes>
                    <Route path="/" element={<Home />}/>              
                    <Route path="*" element={<Home />}/>
                </Routes>
            </BaseLayout>
        </BrowserRouter>
    )
}

export default App
