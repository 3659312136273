import React, { useContext, useEffect } from 'react'
import { MobileMenuContext } from './../contexts/MobileMenuContext'

import './../scss/Overlay.scss'

function Overlay() {
    const {isMobileMenuActive, setIsMobileMenuActive} = useContext(MobileMenuContext)

    useEffect(() => {
        let bodyElClassList = document.querySelector('body').classList;
        let contentWrapperElClassList = (document.querySelector('.content-wrapper')) ? document.querySelector('.content-wrapper').classList : null;
        
        if (isMobileMenuActive) {
            if (!bodyElClassList.contains('no-scroll')) bodyElClassList.add('no-scroll');
            if (contentWrapperElClassList !== null) {
                if (!contentWrapperElClassList.contains('content-wrapper--blur')) contentWrapperElClassList.add('content-wrapper--blur');
            }   
        } else {
            if (bodyElClassList.contains('no-scroll')) bodyElClassList.remove('no-scroll');
            if (contentWrapperElClassList !== null) {
                if (contentWrapperElClassList.contains('content-wrapper--blur')) contentWrapperElClassList.remove('content-wrapper--blur');
            }
        }
    }, [isMobileMenuActive])
    

    return (
        <div className={isMobileMenuActive ? `overlay overlay--active` : `overlay`} onClick={() => setIsMobileMenuActive(false)}></div>
    )
}

export default Overlay
