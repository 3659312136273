import React, { useState } from 'react';
import { MobileMenuContext } from '../contexts/MobileMenuContext';
import Header from './partials/Header';
import Footer from './partials/Footer';
import MobileNavigation from '../components/MobileNavigation';
import Overlay from '../components/Overlay';

const BaseLayout = ({ children }) => {
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false)
    return (
        <React.Fragment>
        <MobileMenuContext.Provider value={{ setIsMobileMenuActive, isMobileMenuActive }}>
            <div className={`content-wrapper`}>
                <Header />  
                <main className="content">{children}</main>
                <Footer />       
            </div> 
            <Overlay />
            <MobileNavigation />    
        </MobileMenuContext.Provider>
        </React.Fragment>
    );
};

export default BaseLayout;