import React, { useEffect, useRef } from 'react'
import './../scss/Working.scss'

function Working({ width = '48px', type = 'ring', height = '140px' }) {
    const spinner = useRef(null);
    const workingWrapper = useRef(null);
    
    useEffect(() => {
        spinner.current.style.setProperty('--spinner-width', width);
        workingWrapper.current.style.setProperty('--working-height', height);
    }, [spinner, width, workingWrapper, height])

    return ( 
        <div className="working-wrapper" ref={workingWrapper}>
            <div className="spinner" ref={spinner}>       
                <div className={ (type === 'pie') ? `spinner__wrapper spinner--pie` : `spinner__wrapper spinner--ring`}>
                    <span className="spinner__left">
                        <span className="spinner__anim"></span>
                    </span>
                    <span className="spinner__right">
                        <span className="spinner__anim"></span>
                    </span>
                </div> 
            </div>
        </div>
    )
}

export default Working