import { statesList } from '../settings/AppSettings';
import parse from 'html-react-parser'

import './../scss/AffiliatesList.scss';

function AffiliatesList({
    stateId, 
    affiliates, 
    network, 
    hideSelectOnResults, 
    toggleResultsView
}) {
    function affiliatesByState() {
        if (stateId === '') return [];
        
        return affiliates.filter((affiliate) => affiliate.state === stateId.toUpperCase()).sort((a, b) => (a.city > b.city) ? 1 : -1);
    }

    function stateName() {
        if (!stateId) return "";

        let state =  statesList.find((state) => state.abbreviation === stateId.toLowerCase()).name;

        return (state) ? state : "";
    }

    return (
        <section className="affiliates-list">
            <div className="affiliates-list__title-row">
                <h4 className="affiliates-list__title">{ stateName() } <span className="affiliates-list__hide-on-mobile">Market</span></h4>
                {hideSelectOnResults && <button
                    onClick={toggleResultsView}
                    className="affiliates-list__toggle-btn"
                >Change State</button>}
            </div>
            <div className="affiliates-list__row affiliates-list__header-row">
                <div className="affiliates-list__header affiliates-list__city">City</div>
                <div className="affiliates-list__header affiliates-list__call-letters">Station</div>
                <div className="affiliates-list__header affiliates-list__antenna">Antenna</div>
                <div className="affiliates-list__header affiliates-list__statellite">Satellite</div>
                <div className="affiliates-list__header affiliates-list__cable">Cable</div>
            </div>
            {(affiliatesByState().length) ? affiliatesByState().map((aff) => (
                <div 
                    key={`aff-${aff.id}`} 
                    className="affiliates-list__row" 
                >
                    <div className="affiliates-list__item affiliates-list__city">{ parse(aff.city ?? '') }</div>
                    <div className="affiliates-list__item affiliates-list__call-letters"><span className="affiliates-list__mobile-label">Station: </span>{ aff.call_letters }</div>
                    <div className="affiliates-list__item affiliates-list__antenna"><span className="affiliates-list__mobile-label">Antenna: </span>{ aff.ota }</div>
                    <div className="affiliates-list__item affiliates-list__statellite"><span className="affiliates-list__mobile-label">Satellite: </span>{ aff.satellite }</div>
                    <div className="affiliates-list__item affiliates-list__cable"><span className="affiliates-list__mobile-label">Cable: </span>{ parse(aff.cable ?? '') }</div>
                </div>)
            ) : (
                <div className="affiliates-list__row" >
                    <div className="affiliates-list__item affiliates-list__no-results">Sorry there are no channels carrying { network } in your area at this time.</div>
                </div>
            )}
        </section>
    );
}

export default AffiliatesList;