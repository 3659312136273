import React, { useState } from 'react'
import { ScheduleContext } from '../contexts/ScheduleContext';
import ScheduleList from './ScheduleList'
import ScheduleNav from './ScheduleNav'

import './../scss/Schedule.scss'

function Schedule() {
    
    const [day, setDay] = useState(new Date())
    return (
        <section className='schedule'>
            <header className='schedule__header'>
                <h2>ION Mystery Programming Schedule</h2>
            </header>
            <ScheduleContext.Provider value={ {day, setDay} }>
                <ScheduleNav />                
                <ScheduleList/>                
            </ScheduleContext.Provider>
        </section>
    )
}

export default Schedule